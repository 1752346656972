import React, { useState } from "react";
import Footer from "./Footer";
import "./Css/contactus.css";
import Navbar from './Navbar'


const Careerpage = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_no: "",
    location: "",
    current_role: "",
    agree: false,
    resume: null,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [responseMessage, setResponseMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");

  const handleChange = (e) => {
    const { name, type, value, checked, files } = e.target;
  
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else if (type === "file") {
      const file = files[0]; // Get the first file
      setFormData((prevData) => ({
        ...prevData,
        [name]: file, // Store the File object
      }));
  
      setSelectedFileName(file ? file.name : ""); 
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  

  const validateForm = () => {
    const newErrors = {};
    if (!formData.first_name) newErrors.first_name = "First name is required.";
    if (!formData.last_name) newErrors.last_name = "Last name is required.";
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid.";
    }
    if (!formData.phone_no) {
      newErrors.phone_no = "Phone number is required.";
    } else if (!/^\d+$/.test(formData.phone_no)) {
      newErrors.phone_no = "Phone number must contain only digits.";
    }
    if (!formData.location) newErrors.location = "location is required.";
    if (!formData.current_role) newErrors.current_role = "current_role is required.";
    if (!formData.resume) newErrors.resume = "Resume is required.";
    if (!formData.agree) newErrors.agree = "You must agree to the terms and conditions.";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setResponseMessage("Validation failed! Please fix the errors.");
      setIsSuccess(false);
      return;
    }
  
    setErrors({});
    setResponseMessage("");
    setIsSuccess(false);
    setIsSubmitting(true); // Start loader
  
    const formDataToSubmit = new FormData();
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null) {
        formDataToSubmit.append(key, formData[key]); // Append only non-null values
      }
    });
  
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Basic eG9wc3RlY2hub2xvZ2llczpZdHJzZGdIeXlmcm9pZnloc2ZmZkRnZmpz",
      },
      body: formDataToSubmit, // Use FormData for the request body
    };
  
    try {
      const response = await fetch(
        "https://clientconnect.vilvabusiness.com/api/xops/career/submitform",
        requestOptions
      );
      const result = await response.json();
  
      if (response.ok) {
        setResponseMessage("Form submitted successfully!");
        setIsSuccess(true);
        setTimeout(() => setResponseMessage(""), 3000);
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          phone_no: "",
          location: "",
          current_role: "",
          agree: false,
          file: null,
        });
        setSelectedFileName('');
      } else {
        if (result.errors) {
          const apiErrors = {};
          Object.keys(result.errors).forEach((field) => {
            apiErrors[field] = result.errors[field][0];
          });
          setErrors(apiErrors);
        }
        setResponseMessage(result.message || "Something went wrong.");
        setIsSuccess(false);
        setTimeout(() => setResponseMessage(""), 3000);
      }
    } catch (error) {
      console.error("Error:", error);
      setResponseMessage("Network error occurred.");
      setIsSuccess(false);
    } finally {
      setIsSubmitting(false); // Stop loader
    }
  };


  return (
    <div>
      <Navbar />
      <div className="contactus_all py-5">
        <div className="contact_us_des pt-5 pb-4">
          <h2>Join Us for Your Career Growth</h2>
          <p>
            Ready to elevate your career? Join us to grow professionally in a dynamic environment where your skills are nurtured, and your contributions matter. Whether you're starting out or experienced, take the next step and shape the future with us!
          </p>


        </div>
        <div className="form_container">
          <form action="" className="mx-auto" onSubmit={handleSubmit}>
            <div style={{ marginBottom: '24px' }}>
              <label htmlFor="First Name" className="form-label">
                First Name
              </label>
              <input
                type="text"
                className="form_control_input"
                id="name"
                name="first_name"
                required
                placeholder="Enter Your First Name"
                value={formData.first_name}
                onChange={handleChange}
              />
              {errors.first_name && <p className="error" style={{ color: "red" }}>{errors.first_name}</p>}
            </div>
            <div style={{ marginBottom: '24px' }}>
              <label htmlFor="Last Name" className="form-label">
                Last Name
              </label>

              <input
                type="text"
                className="form_control_input"
                id="name"
                name="last_name"
                required
                placeholder="Enter Your Last Name"
                value={formData.last_name}
                onChange={handleChange}
              />
              {errors.last_name && <p className="error" style={{ color: "red" }}>{errors.last_name}</p>}

            </div>
            <div style={{ marginBottom: '24px' }}>
              <label htmlFor="Email" className="form-label">
                Email
              </label>

              <input
                type="email"
                className="form_control_input"
                id="name"
                name="email"
                required
                placeholder="Enter Your Email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <p className="error" style={{ color: "red" }}>{errors.email}</p>}
            </div>

            <div style={{ marginBottom: '24px' }}>
              <label htmlFor="Number" className="form-label">
                Phone Number
              </label>

              <input
                type="text"
                className="form_control_input"
                id="name"
                name="phone_no"
                required
                placeholder="Enter Your Phone Number"
                value={formData.phone_no}
                onChange={handleChange}
              />
              {errors.phone_no && <p className="error" style={{ color: "red" }} >{errors.phone_no}</p>}

            </div>

            <div style={{ marginBottom: '24px' }} >
              <label htmlFor="current_role" className="form-label">
                Current Role
              </label>
<div className="relative">
              <select
                className="form_control_input"
                id="current_role"
                name="current_role"
                required
                placeholder="Select Current Role"
                value={formData.current_role}
                onChange={handleChange}
                style={{
                  appearance: 'none', // Remove default arrow
                  WebkitAppearance: 'none', // For Safari
                  MozAppearance: 'none', // For Firefox
                  paddingRight: '30px', // Adjust padding for custom arrow
                }}
              >
                
                <option value="" disabled selected>
                  Select Your Role
                </option>
                <option value="DevOps">DevOps</option>
                <option value="SecOps">SecOps</option>
                <option value="Others">Others</option>
              </select>
              <span className="arrow_select"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
  <path d="M6.36391 4.94972L11.3137 0L12.7279 1.41421L6.36391 7.77822L0 1.41421L1.41421 0L6.36391 4.94972Z" fill="white"/>
</svg></span>
</div>
              {errors.current_role && <p className="error" style={{ color: "red" }}>{errors.current_role}</p>}
            </div>


            <div style={{ marginBottom: '24px' }}>
              <label htmlFor="location" className="form-label">
                Location
              </label>
              <div className="relative">
              <select
                className="form_control_input"
                id="current_role"
                name="location"
                required
                placeholder="Select location"
                value={formData.location}
                onChange={handleChange}
                style={{
                  appearance: 'none', 
                  WebkitAppearance: 'none', 
                  MozAppearance: 'none',
                  paddingRight: '30px', 
                }}
              >
                <option value="" disabled selected>
                  Select Your location
                </option>
                <option value="india">India</option>
                <option value="usa">United States</option>
                <option value="uk">United Kingdom</option>
                <option value="canada">Canada</option>
                <option value="australia">Australia</option>
                <option value="germany">Germany</option>
                <option value="france">France</option>
                <option value="japan">Japan</option>
                <option value="china">China</option>
                <option value="brazil">Brazil</option>
                <option value="south africa">South Africa</option>
                <option value="russia">Russia</option>
                <option value="mexico">Mexico</option>
                <option value="italy">Italy</option>
                <option value="spain">Spain</option>
              </select>
              <span className="arrow_select"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
  <path d="M6.36391 4.94972L11.3137 0L12.7279 1.41421L6.36391 7.77822L0 1.41421L1.41421 0L6.36391 4.94972Z" fill="white"/>
</svg></span>
</div>

              {errors.location && <p className="error" style={{ color: "red" }}>{errors.location}</p>}
            </div>


            <div style={{ marginBottom: '24px' }}>
  <label htmlFor="Resume" className="form-label">
    Upload Your Resume
  </label>
  <div className="file-upload-container">
    <div className="upload-content">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          d="M20.6903 8.33333H34.9999C35.9204 8.33333 36.6666 9.07953 36.6666 10V33.3333C36.6666 34.2538 35.9204 35 34.9999 35H4.99992C4.07945 35 3.33325 34.2538 3.33325 33.3333V6.66667C3.33325 5.7462 4.07945 5 4.99992 5H17.3569L20.6903 8.33333ZM21.6666 21.6667H26.6666L19.9999 15L13.3333 21.6667H18.3333V28.3333H21.6666V21.6667Z"
          fill="#A2A2A2"
        />
      </svg>
      <div className="upload-text">
        <p className="browse-files">Browse Files</p>
        <p className="drag-drop-text">Drag and Drop Resume Here</p>
        <div className="file-names">
        {selectedFileName && (
        <div className="file-preview">
          <p>Selected File: {selectedFileName}</p>
        </div>
      )}
      </div>
      </div>
    </div>
    <input
    multiple
      type="file"
      className="file-input"
      id="resume"
      name="resume"
      onChange={handleChange}
    />
  </div>
  {errors.resume && <p className="error" style={{ color: "red" }}>{errors.resume}</p>}
</div>


            <div className="terms-container">
              <input
                type="checkbox"
                name="agree"
                checked={formData.agree}
                onChange={handleChange}
                className="custom-checkbox mt-1"
              />
              <label htmlFor="terms" style={{ width: "90%" }}>
              By joining, I consent to XOPS using my contact details per the privacy policy to share career opportunities, growth tips, updates, and offers.
              </label>
            </div>
            {errors.agree && <p className="error" style={{ color: "red" }}>{errors.agree}</p>}
            {/* {responseMessage && <p className="response" style={{color:"red"}}>{responseMessage}</p>} */}
            <button type="submit">{isSubmitting ? "Submitting..." : "Submit Application"}</button>

            {/* Popup message */}
            <div className={`popup ${responseMessage ? "show" : ""} ${isSuccess ? "success" : "error"}`}>
              {responseMessage}
            </div>

            <style jsx>{`
        .input_sec {
          display: flex;
          flex-direction: column;
          margin-bottom: 15px;
        }

        input {
          padding: 8px 12px;
          border-radius: 4px;
          font-size: 14px;
          border: 1px solid #ccc;
          transition: border-color 0.3s ease, color 0.3s ease;
        }

        .error-text {
          color: red;
          font-size: 12px;
        }

        button {
          padding: 8px 12px;
          border: none;
          background-color: #998be5;
          color: white;
          border-radius: 4px;
          cursor: pointer;
        }

        .popup {
          position: fixed;
          top: -100px;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 12px;
          background: rgba(50, 50, 50, 0.8);
          width: 320px;
          padding: 20px;
          text-align: center;
          color: #fff;
          font-family: "Suisse Int'l", sans-serif;
          font-size: 15px;
          font-weight: 700;
          transition: top 0.5s ease, opacity 0.5s ease;
          opacity: 0;
          z-index: 1000;
        }

        .popup.show {
          top: 20px;
          opacity: 1;
        }

        .popup.success {
          color:#998be5;
           background: rgba(50, 50, 50, 0.4);
          box-shadow: 0px 0px 20px 0px rgba(105, 96, 151, 0.5);
          backdrop-filter: blur(5px);
        }

        .popup.error {
          background: rgba(205, 50, 50, 0.9); /* Red background for error */
          border: 2px solid red;
        }
      `}</style>
          </form>

        </div>
      </div>

      <Footer />
    </div>
  );
};


export default Careerpage;
