import React, { useState } from "react";
import Footer from "./Footer";
import "./Css/contactus.css";
import Navbar from './Navbar'

const Contactus = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_no: "",
    country: "",
    industry: "",
    agree: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [responseMessage, setResponseMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked, // Update checkbox state
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.first_name) newErrors.first_name = "First name is required.";
    if (!formData.last_name) newErrors.last_name = "Last name is required.";
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid.";
    }
    if (!formData.phone_no) {
      newErrors.phone_no = "Phone number is required.";
    } else if (!/^\d+$/.test(formData.phone_no)) {
      newErrors.phone_no = "Phone number must contain only digits.";
    }
    if (!formData.country) newErrors.country = "Country is required.";
    if (!formData.industry) newErrors.industry = "Industry is required.";
    if (!formData.agree) newErrors.agree = "You must agree to the terms and conditions.";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setResponseMessage("Validation failed! Please fix the errors.");
      setIsSuccess(false);
      return;
    }

    setErrors({});
    setResponseMessage("");
    setIsSuccess(false);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Basic eG9wc3RlY2hub2xvZ2llczpZdHJzZGdIeXlmcm9pZnloc2ZmZkRnZmpz"
    );

    const raw = JSON.stringify(formData);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setIsSubmitting(true); // Start loader

    try {
      const response = await fetch(
        "https://clientconnect.vilvabusiness.com/api/xops/submitform",
        requestOptions
      );
      const result = await response.json();

      if (response.ok) {
        setResponseMessage("Form submitted successfully!");
        setIsSuccess(true);
        setTimeout(() => setResponseMessage(""), 3000);
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          phone_no: "",
          country: "",
          industry: "",
          agree: false,
        });
      } else {
        if (result.errors) {
          const apiErrors = {};
          Object.keys(result.errors).forEach((field) => {
            apiErrors[field] = result.errors[field][0]; 
          });
          setErrors(apiErrors);
        }
        setResponseMessage(result.message || "Something went wrong.");
        setIsSuccess(false);
        setTimeout(() => setResponseMessage(""), 3000);
      }
    } catch (error) {
      console.error("Error:", error);
      setResponseMessage("Network error occurred.");
      setIsSuccess(false);
    } finally {
      setIsSubmitting(false); // Stop loader
    }
  };


  return (
    <div>
      <Navbar />
      <div className="contactus_all py-5">
        <div className="contact_us_des pt-5 pb-4">
          <h2>Let's Connect and Collaborate</h2>
          <p>
            Have questions, need support, or ready to discuss your next big
            idea? We’re here to help! Reach out to us, and let’s build solutions
            that drive success together. Your journey starts with a simple
            message—contact us today!
          </p>
        </div>
        <div className="form_container">
          <form action="" className="mx-auto" onSubmit={handleSubmit}>
            <div style={{ marginBottom: '24px' }}>
              <label htmlFor="name" className="form-label">
                First Name
              </label>
              <input
                type="text"
                className="form_control_input"
                id="name"
                name="first_name"
                required
                placeholder="Enter Your First Name"
                value={formData.first_name}
                onChange={handleChange}
              />
              {errors.first_name && <p className="error" style={{ color: "red" }}>{errors.first_name}</p>}
            </div>
            <div style={{ marginBottom: '24px' }}>
              <label htmlFor="country" className="form-label">
                Last Name
              </label>

              <input
                type="text"
                className="form_control_input"
                id="name"
                name="last_name"
                required
                placeholder="Enter Your Last Name"
                value={formData.last_name}
                onChange={handleChange}
              />
              {errors.last_name && <p className="error" style={{ color: "red" }}>{errors.last_name}</p>}

            </div>
            <div style={{ marginBottom: '24px' }}>
              <label htmlFor="country" className="form-label">
                Business Email
              </label>

              <input
                type="email"
                className="form_control_input"
                id="name"
                name="email"
                required
                placeholder="Enter Your Email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <p className="error" style={{ color: "red" }}>{errors.email}</p>}
            </div>
            <div style={{ marginBottom: '24px' }}>
              <label htmlFor="country" className="form-label">
                Country
              </label>
<div className="relative">
              <select
                className="form_control_input"
                id="industry"
                name="country"
                required
                placeholder="Select Country"
                value={formData.country}
                onChange={handleChange}
                style={{
                  appearance: 'none', 
                  WebkitAppearance: 'none', 
                  MozAppearance: 'none', 
                  paddingRight: '30px', 
                }}
              >
                <option value="" disabled selected>
                  Select Your Country
                </option>
                <option value="india">India</option>
                <option value="usa">United States</option>
                <option value="uk">United Kingdom</option>
                <option value="canada">Canada</option>
                <option value="australia">Australia</option>
                <option value="germany">Germany</option>
                <option value="france">France</option>
                <option value="japan">Japan</option>
                <option value="china">China</option>
                <option value="brazil">Brazil</option>
                <option value="south africa">South Africa</option>
                <option value="russia">Russia</option>
                <option value="mexico">Mexico</option>
                <option value="italy">Italy</option>
                <option value="spain">Spain</option>
              </select>
              <span className="arrow_select"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
  <path d="M6.36391 4.94972L11.3137 0L12.7279 1.41421L6.36391 7.77822L0 1.41421L1.41421 0L6.36391 4.94972Z" fill="white"/>
</svg></span>
</div>

              {errors.country && <p className="error" style={{ color: "red" }}>{errors.country}</p>}
            </div>

            <div style={{ marginBottom: '24px' }}>
              <label htmlFor="country" className="form-label">
                Phone Number
              </label>

              <input
                type="text"
                className="form_control_input"
                id="name"
                name="phone_no"
                required
                placeholder="Enter Your Phone Number"
                value={formData.phone_no}
                onChange={handleChange}
                
              />
              {errors.phone_no && <p className="error" style={{ color: "red" }} >{errors.phone_no}</p>}

            </div>
            <div style={{ marginBottom: '24px' }}>
              <label htmlFor="industry" className="form-label">
                Industry
              </label>
<div className="relative">
              <select
                className="form_control_input"
                id="industry"
                name="industry"
                required
                placeholder="Select Industry"
                value={formData.industry}
                onChange={handleChange}
                style={{
                  appearance: 'none', 
                  WebkitAppearance: 'none', 
                  MozAppearance: 'none', 
                  paddingRight: '30px', 
                }}
              >
                <option value="" disabled selected>
                  Select Your Industry
                </option>
                <option value="technology it">Technology and IT</option>
                <option value="ecommerce retail">E-commerce and Retail</option>
                <option value="healthcare">Healthcare</option>
                <option value="manufacturing">Manufacturing</option>
                <option value="finance">Finance</option>
                <option value="others">Others</option>
              </select>
              <span className="arrow_select"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
  <path d="M6.36391 4.94972L11.3137 0L12.7279 1.41421L6.36391 7.77822L0 1.41421L1.41421 0L6.36391 4.94972Z" fill="white"/>
</svg></span>
</div>
              
              {errors.industry && <p className="error" style={{ color: "red" }}>{errors.industry}</p>}
            </div>

            <div className="terms-container">
              <input
                type="checkbox"
                name="agree"
                checked={formData.agree}
                onChange={handleChange}
                className="custom-checkbox mt-1"
              />
              <label htmlFor="terms" style={{ width: "90%" }}>
                I agree to the privacy policy and consent to XOPS using my contact details to share marketing tips, updates, and exclusive offers.
              </label>
            </div>
            {errors.agree && <p className="error" style={{ color: "red" }}>{errors.agree}</p>}
            <button
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>

            <div className={`popup ${responseMessage ? "show" : ""} ${isSuccess ? "success" : "error"}`}>
              {responseMessage}
            </div>

            <style jsx>{`
        .input_sec {
          display: flex;
          flex-direction: column;
          margin-bottom: 15px;
        }

        input {
          padding: 8px 12px;
          border-radius: 4px;
          font-size: 14px;
          border: 1px solid #ccc;
          transition: border-color 0.3s ease, color 0.3s ease;
        }

        .error-text {
          color: red;
          font-size: 12px;
        }

        button {
          padding: 8px 12px;
          border: none;
          background-color: #998be5;
          color: white;
          border-radius: 4px;
          cursor: pointer;
        }

        .popup {
          position: fixed;
          top: -100px;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 12px;
          background: rgba(50, 50, 50, 0.8);
          width: 320px;
          padding: 20px;
          text-align: center;
          color: #fff;
          font-family: "Suisse Int'l", sans-serif;
          font-size: 15px;
          font-weight: 700;
          transition: top 0.5s ease, opacity 0.5s ease;
          opacity: 0;
          z-index: 1000;
        }

        .popup.show {
          top: 20px;
          opacity: 1;
        }

        .popup.success {
          color:#998be5;
           background: rgba(50, 50, 50, 0.4);
          box-shadow: 0px 0px 20px 0px rgba(105, 96, 151, 0.5);
          backdrop-filter: blur(5px);
        }

        .popup.error {
          background: rgba(205, 50, 50, 0.9); /* Red background for error */
          border: 2px solid red;
        }
      `}</style>
          </form>

        </div>
      </div>

      <Footer />
    </div>
  );
};


export default Contactus;
