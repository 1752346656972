import React, { useState } from "react";
import xtlogo from "./Images/xtlogo.png";
import "./Css/Homepage.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';


const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("");

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const offset = -80; // Adjust this value to control the space above the section
      const top = section.getBoundingClientRect().top + window.scrollY + offset;
  
      window.scrollTo({
        top,
        behavior: "smooth",
      });
      
      setActiveSection((prevActive) =>
        prevActive === sectionId ? "" : sectionId
      );
    }
  };
  return (
    <div>
      <div className="navbar_all container-fluid px-4">
 
        {/* Mobile menu button */}
        <div className="mobile_menu_icon" onClick={toggleMobileMenu}>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M3 6h18M3 12h18M3 18h18"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
   
        </div>

        {/* Logo */}
        <div className="logo_container">
          <a href="/">
            <img src={xtlogo} alt="XT Logo" style={{ width: "40px", height: "40px" }} />
          </a>
          <div className="contact_btn">
            <a href="/contactus">Contact Now</a>
          </div>
        </div>

        {/* Navigation Menu */}
        <ul className={`nav_list ${isMobileMenuOpen ? "open" : ""}`}>
        <FontAwesomeIcon  onClick={toggleMobileMenu} className="close_btn d-block d-md-none"  color="white" icon={faX} />
      <li>
        <a href="/" style={{ color: "#998BE5" }}>Home</a>
      </li>
      <li>
        <a
          href="#"
          onClick={() => scrollToSection("Solutions")}
          style={{
            color: activeSection === "Solutions" ? "#998BE5" : "white",
            textDecoration: "none",
          }}
        >
          Solutions
        </a>
      </li>
      <li>
        <a
          href="#products"
          onClick={() => scrollToSection("products")}
          style={{
            color: activeSection === "products" ? "#998BE5" : "white",
            textDecoration: "none",
          }}
        >
          Products
        </a>
      </li>
      <li>
        <a href="/career" >Career</a>
      </li>
      <li>
        <a href="#Industries"  onClick={() => scrollToSection("Industries")}
          style={{
            color: activeSection === "products" ? "#998BE5" : "white",
            textDecoration: "none",
          }} >Industries</a>
      </li>
    </ul>
    <div className="contact_btn_desk d-none d-lg-block">
            <a href="/contactus">Contact Now</a>
          </div>
        <div className="main_nav_fade">
          
        </div>
      </div>
    </div>
  );
};

export default Navbar;
