import React from 'react';
import './boxani.css';
import designvideo1 from '../images/Comp 1 1.mp4';

export const BoxAnima = () => {
  return (
    <div className="box">
      <div className="video_container">
        <div className="animated_border"></div>
        <video autoPlay muted loop playsInline className="video">
          <source src={designvideo1} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};
