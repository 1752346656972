import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from './pages/Homepage.jsx';
import "bootstrap/dist/css/bootstrap.min.css";
import { BoxAnima } from './pages/boxAnima.jsx';
import Careerpage from './pages/Careerpage.jsx';
import Contactus from './pages/Contactus.jsx';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/boxanimation" element={<BoxAnima />} />
          <Route path="/career" element={<Careerpage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
